<template>
  <div></div>
</template>
<script>
import Vue from "vue";
import { getCookie, setCookie } from "../utils/CookieManager";
import remoteLoad from "../assets/remoteload";

export default {
  name: "Home",
  data() {
    return { captchaImage: "", captchaValue: "", status: "正在登陆..." };
  },
  methods: {
    async submit(loginId, userRole) {
      let response = await Vue.prototype.$api.login.userLogin({
        loginId: loginId,
        role: userRole,
      });
      if (response.token) {
        setCookie("vb_token", response.token, "365");
        if (userRole != "Employee") {
          window.localStorage.setItem("empIdIsEmployee", false);
          this.$router.push("/admin-vehicle-list");
        } else {
          window.localStorage.setItem("empIdIsEmployee", true);
          this.$router.push("/vehicle-list");
        }
      }
    },

    fetchImage() {
      Vue.prototype.$api.login.fetchVerifyCode().then((data) => {
        this.captchaImage = data;
      });
    },

    loadWeComUrl() {
      let local = window.location.href;
      let string = (((1 + Math.random()) * 0x10000) | 0)
        .toString(16)
        .substring(1);
      let uuid = "";
      if (getCookie("uuid")) {
        uuid = getCookie("uuid");
      } else {
        uuid =
          string +
          string +
          "-" +
          string +
          "-" +
          string +
          "-" +
          string +
          "-" +
          string;
        setCookie("uuid", uuid, "365");
      }
      if (local.split("?").length > 1) {
        local = local.split("?")[0];
      }
      if (local.split("#").length > 1) {
        local = local.split("#")[0];
      }
      let wecomUri =
              process.env.VUE_APP_WECOM_URL + "/connect/oauth2/authorize?appid=" + process.env.VUE_APP_APPID + "&redirect_uri=" +
        local +
        "&response_type=code&snsapi_userinfo=snsapi_base&agentid=" + process.env.VUE_APP_AGETNID+"&state=" +
        uuid +
        "#wechat_redirect";
      window.location.href = wecomUri;
    },

    getCode() {
      this.code = "";
      this.code = this.getUrlCode().code;
      if (this.code == null || this.code === "") {
        this.loadWeComUrl();
      } else {
        setCookie("weComCode", this.code, "365");
        this.toGetSignature(
          parseInt(Math.round(new Date().getTime() / 1000).toString()),
          this.randomString(16),
          encodeURIComponent(window.location.href.split("#")[0])
        );
      }
    },

    randomString(e) {
      e = e || 32;
      let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    async toGetSignature(timestamp, nonceStr, url) {
      let response = await this.$api.vehicle.toGetSignature(
        timestamp,
        nonceStr,
        url
      );
      if (response) {
        wx.config({
          beat: true,
          debug: false,
          appId: process.env.VUE_APP_APPID,
          timestamp: timestamp,
          nonceStr: nonceStr,
          signature: response,
          jsApiList: ["getLocation", "chooseImage"],
        });
        wx.ready(function () {
          wx.hideAllNonBaseMenuItem();
        });
      }
      this.loginByCode();
    },

    getUrlCode() {
      let url = window.location.href;
      this.winUrl = url;
      let theRequest = new Object();
      if (url.indexOf("?") !== -1) {
        let path = url.split("?")[1];
        let str = path.substr(0);
        let strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },

    async loginByCode() {
      let response = await Vue.prototype.$api.login.userLoginByCode(
        getCookie("weComCode")
      );

      if (response.status_code === 200) {
        window.localStorage.setItem("userRole", JSON.stringify(response.data));
        if (response.data.length > 1) {
          this.$router.push("/chooseRole");
        } else {
          let loginId = response.data.WIWID;
          let role = response.data.role
          this.submit(loginId, role);
        }
      } else if (response.status_code === (500 || 401)) {
        this.$router.replace("/error");
      } else if (response.status_code === 40029) {
        window.location.reload();
      }
    },
  },
  mounted() {
    setTimeout(this.getCode, 300);
  },

  created() {
    remoteLoad("https://res.wx.qq.com/open/js/jweixin-1.2.0.js");
  },
};
</script>
<style scoped></style>
