import Vue from "vue";

export function setCookie(cname, value, exPireDay) {
  let exDate = new Date();
  exDate.setDate(exDate.getDate() + exPireDay);
  document.cookie =
    cname +
    "=" +
    escape(value) +
    (exPireDay == null ? "" : ";expires=" + exDate.toGMTString());
}

export function getCookie(cname) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(cname + "=");
    if (c_start != -1) {
      c_start = c_start + cname.length + 1;
      let c_end = document.cookie.indexOf(";", c_start);
      if (c_end == -1) c_end = document.cookie.length;
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
}

export function getEmployeeEmpId() {
  let empId = '';
  try {
    let userDatas = JSON.parse(window.localStorage.getItem("userRole"));
    let empIdIsEmployee = JSON.parse(window.localStorage.getItem("empIdIsEmployee"));
    if (empIdIsEmployee) {
      userDatas.forEach(function (item) {
        console.log('Employee',item.role)
        if (item.role === "Employee"||item.role === "External Delegate") {
          console.log('Employee||External Delegate')
          empId = item.empId;
        }
      })
    } else {
      userDatas.forEach(function (item) {
        console.log('System_Admin',item.role)
        if (item.role === "System_Admin"||item.role === "Fleet_Admin") {
          console.log('System_Admin||Fleet_Admin')
          empId = item.empId;
        }
      })
    }
  } catch (err) {}

  return empId;
}