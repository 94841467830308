<template>
  <div class="stockTakingSummary">
    <ul class="info-list-wrap label-wrap">
      <li class="info-list label-info-height">
        {{ $t("lang.PlateNumber") }}
        <div class="userDetail">{{ this.plateNumber }}</div>
      </li>
      <li class="info-list label-info-height">
        {{ $t("lang.CarModel") }}
        <div class="userDetail">{{ this.carModel }}</div>
      </li>
      <li class="info-list label-info-height">
        {{ $t("lang.VIN") }}
        <div class="userDetail">{{ this.VIN }}</div>
      </li>
    </ul>
    <div class="spacing"></div>
    <ul class="info-list-wrap">
      <div class="Vehicle-basic-information" @click="clickBaseInfo">
        <img src="@/assets/img/icon-car_front.png" alt="" />
        {{ $t("lang.VehicleBasicInformation") }}
        <img src="@/assets/img/icon-correct.png" alt="" />
        <div class="unflod">
          <img
            class="drop-down-icon"
            v-show="baseInfo"
            src="@/assets/img/icon-arrowdown.png"
            alt=""
          />
          <img
            class="drop-down-icon"
            v-show="!baseInfo"
            src="@/assets/img/icon-arrowup.png"
            alt=""
          />
        </div>
      </div>
      <div class="info-list-content" v-show="!baseInfo">
        <li class="info-list main-info-height">
          {{ $t("lang.Location") }}
          <div class="userDetail">
            <img src="@/assets/img/icon-location-done.png" alt="" />
            {{ $t("lang.MyLocationObtained") }}
          </div>
        </li>
        <!-- <li class="info-list main-info-height">
          {{ $t("lang.ExteriorColour") }}
          <div class="userDetail">
            <p>{{ this.$store.state.carBodyPhoto }}</p>
          </div>
        </li> -->
        <!-- <li class="info-list main-info-height">
          {{ $t("lang.InteriorPhoto") }}
          <div class="userDetail">
            <p>{{ this.$store.state.interiorPhoto }}</p>
          </div>
        </li> -->
        <li class="info-list main-info-height">
          {{ $t("lang.ExteriorPhoto") }}
        </li>
        <li class="info-list main-info-height">
          <div class="userDetail-photo">
            <div class="camera-photo-small">
              <img
                class="wx-photo"
                v-show="leftFrontResult"
                v-bind:key="leftFrontResult"
                :src="leftFrontResult"
                alt=""
              />
              <img
                class="wx-photo"
                v-show="rightBackResult"
                v-bind:key="rightBackResult"
                :src="rightBackResult"
                alt=""
              />
            </div>
          </div>
        </li>
      </div>
    </ul>
    <ul class="info-list-wrap">
      <div class="Vehicle-basic-information" @click="clickVehicleLicense">
        <img src="@/assets/img/icon-Identity_Card.png" alt="" />
        {{ $t("lang.VehicleLicense") }}
        <img src="@/assets/img/icon-correct.png" alt="" />
        <div class="unflod">
          <img
            v-show="vehicleLicense"
            class="drop-down-icon"
            src="@/assets/img/icon-arrowdown.png"
            alt=""
          />
          <img
            v-show="!vehicleLicense"
            class="drop-down-icon"
            src="@/assets/img/icon-arrowup.png"
            alt=""
          />
        </div>
      </div>
      <div class="info-list-content" v-show="!vehicleLicense">
        <li class="info-list main-info-height">
          <div class="userDetail">
            <div class="camera-photo">
              <img class="wx-photo" v-show="result" :src="result" alt="" />
            </div>
          </div>
        </li>
      </div>
    </ul>
    <!-- <ul class="info-list-wrap">
      <div
        class="Vehicle-basic-information"
        @click="clickVehicleSpecificInformation"
      >
        <img src="@/assets/img/icon-list.png" alt="" />
        {{ $t("lang.VehicleSpecificInformation") }}
        <img src="@/assets/img/icon-correct.png" alt="" />
        <div class="unflod">
          <img
            v-show="vehicleSpecificInformation"
            class="drop-down-icon"
            src="@/assets/img/icon-arrowdown.png"
            alt=""
          />
          <img
            v-show="!vehicleSpecificInformation"
            class="drop-down-icon"
            src="@/assets/img/icon-arrowup.png"
            alt=""
          />
        </div>
      </div>
      <div class="info-list-content" v-show="!vehicleSpecificInformation">
        <li class="info-list main-info-height">
          {{ $t("lang.WarningTriangleInCar") }}
          <div class="userDetail">
            <p>{{ this.$store.state.warningTriangleInCar }}</p>
          </div>
        </li>
        <li class="info-list main-info-height">
          {{ $t("lang.FireExtinguisher") }}
          <div class="userDetail">
            <p>{{ this.$store.state.fireExtinguisher }}</p>
          </div>
        </li>
        <li class="info-list main-info-height">
          {{ $t("lang.FloorMat") }}
          <div class="userDetail">
            <p>{{ this.$store.state.floorMat }}</p>
          </div>
        </li>
      </div>
    </ul> -->
    <ul class="info-list-wrap">
      <div class="Vehicle-basic-information" @click="clickMileage">
        <img src="@/assets/img/icon-mileage.png" alt="" />
        {{ $t("lang.Mileage") }}
        <img src="@/assets/img/icon-correct.png" alt="" />
        <div class="unflod">
          <img
            v-show="mileage"
            class="drop-down-icon"
            src="@/assets/img/icon-arrowdown.png"
            alt=""
          />
          <img
            v-show="!mileage"
            class="drop-down-icon"
            src="@/assets/img/icon-arrowup.png"
            alt=""
          />
        </div>
      </div>
      <div class="info-list-content" v-show="!mileage">
        <li class="info-list main-info-height">
          {{ $t("lang.MileageNumber") }}
          <div class="userDetail">
            <p>{{ this.$store.state.mileage }}</p>
          </div>
        </li>
        <li class="info-list main-info-height">
          {{ $t("lang.OdometerPhoto") }}
        </li>
        <li class="info-list main-info-height">
          <div class="userDetail-photo">
            <div class="camera-photo-small">
              <img
                class="wx-photo"
                v-show="exteriorPhoto"
                :src="exteriorPhoto"
                alt=""
              />
            </div>
          </div>
        </li>
      </div>
    </ul>
    <ul class="info-list-wrap">
      <div class="Vehicle-basic-information" @click="clickDamages">
        <img src="@/assets/img/icon-repair.png" alt="" />
        {{ $t("lang.Damages") }}
        <img src="@/assets/img/icon-correct.png" alt="" />

        <div class="unflod">
          <img
            v-show="damages"
            class="drop-down-icon"
            src="@/assets/img/icon-arrowdown.png"
            alt=""
          />
          <img
            v-show="!damages"
            class="drop-down-icon"
            src="@/assets/img/icon-arrowup.png"
            alt=""
          />
        </div>
      </div>
      <div class="info-list-content" v-show="!damages">
        <li class="info-list main-info-height">
          {{ $t("lang.DamageLevel") }}
          <div class="userDetail">
            <p>{{ this.$store.state.damages }}</p>
          </div>
        </li>
        <li class="info-list main-info-height">
          {{ $t("lang.DamagePhoto") }}
        </li>
        <li
          class="info-list main-info-height"
          v-show="damageImgList.length != 0"
        >
          <div class="userDetail-photo">
            <div class="camera-photo-small">
              <img
                v-for="(item, index) in damageImgList"
                class="wx-photo"
                v-show="damageImgList.length != 0"
                v-bind:key="index + index"
                :src="item"
                alt=""
              />
            </div>
          </div>
        </li>
        <li class="info-list main-info-height">
          {{ $t("lang.carIsMobileAndInUse") }}
          <div class="userDetail">
            <p>{{ this.$store.state.carIsMobileAndInUse }}</p>
          </div>
        </li>
      </div>
    </ul>
    <ul>
      <div class="spacing"></div>
      <div class="esign-title-wrap">
        <div class="esign-title">
          <div class="esign-content">
            <img src="@/assets/img/icon-handwriting.png" alt="" />
            <div class="esign-title-font">
              {{ $t("lang.DigitalSignature") }}
            </div>
          </div>

          <p @click="handleReset">{{ $t("lang.Clear") }}</p>
        </div>
        <div class="esign-hint">{{ $t("lang.PleaseSignYourNameBelow") }}</div>
      </div>
      <div class="border-color">
        <vue-esign
          ref="esign"
          :width="800"
          :height="280"
          :isCrop="isCrop"
          :lineWidth="lineWidth"
          :lineColor="lineColor"
          :bgColor.sync="bgColor"
        />
      </div>
    </ul>
    <div class="stock-taking-next-btn">
      <mt-button
        size="large"
        class="stock-taking-btn"
        :disabled="this.disabled"
        :class="toggleType"
        @click="nextStep"
        >{{ $t("lang.Tosubmit") }}</mt-button
      >
    </div>
    <mt-popup v-model="popupVisible">
      <div class="tip-success-wrap">
        <img src="@/assets/img/icon-correct-sign.png" alt="" />
        <p>{{ $t("lang.Success") }}</p>
        <div class="tip-success">
          <div class="tip-success-content">
            {{ $t("lang.TipOfSuccess") }}
          </div>
        </div>
        <div>
          <mt-button size="large" @click="successBtn">{{
            $t("lang.Ikonw")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>
  </div>
</template>
<script>
import { dataURLtoFile } from "@/utils/DataURLtoFile";
import { Indicator } from "mint-ui";
import { getCookie, setCookie } from "../../utils/CookieManager";
export default {
  data() {
    return {
      carModel: "",
      plateNumber: "",
      VIN: "",
      carBodyPhoto: "",
      baseInfo: true,
      vehicleLicense: true,
      vehicleSpecificInformation: true,
      mileage: true,
      damages: true,
      lineWidth: 10,
      lineColor: "#000000",
      bgColor: "",
      resultImg: "",
      isCrop: false,
      disabled: false,
      popupVisible: false,
      checkResult: [],
      photoFile: {},
      inter: null,
      imageList: [],
      damageImages: [],
      toPhotoList: [],
      photoBase: [],
      parseInjurePhoto: [],
      generate: false,
      damageImgList: [],
    };
  },
  created() {
    // if (sessionStorage.getItem("store")) {
    //   this.$store.replaceState(
    //     Object.assign(
    //       {},
    //       this.$store.state,
    //       JSON.parse(sessionStorage.getItem("store"))
    //     )
    //   );
    // }
    if (!this.$store.state.carModel) {
      this.$router.go(-1);
    }
    this.$store.state.information = false;
    this.carModel = this.$store.state.carModel;
    this.VIN = this.$store.state.VIN;
    var plateStr = "";
    plateStr = this.$store.state.plateNumber;
    this.plateNumber = plateStr.slice(0, 2) + " " + plateStr.slice(2);
    this.result = this.$store.state.result;
    this.leftFrontResult = this.$store.state.leftFrontResult;
    this.rightBackResult = this.$store.state.rightBackResult;
    this.exteriorPhoto = this.$store.state.exteriorPhoto;
    this.photoList = this.$store.state.photoList;
    this.damageImgList = this.$store.state.damageImgList;
    this.searchList(this.VIN);
    this.photoList.forEach((e) => {
      this.toPhotoList.push(dataURLtoFile(e.img, e.site));
    });
    this.toPhotoList.push(
      dataURLtoFile(this.leftFrontResult, "leftFrontImage")
    );
    this.toPhotoList.push(
      dataURLtoFile(this.rightBackResult, "rightBackImage")
    );
    this.toPhotoList.push(dataURLtoFile(this.result, "licenseImage"));
    this.toPhotoList.push(dataURLtoFile(this.exteriorPhoto, "odometerImage"));
    window.addEventListener("beforeunload", () => {
      // sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
  computed: {
    toggleType() {
      if (this.generate) {
        this.disabled = false;
        return "verifyBtn";
      }
    },
  },
  watch: {
    $route: {
      handler(to, form) {},
    },
  },
  mounted() {
    this.inter = setInterval(() => {
      this.handleGenerate();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.inter);
    Indicator.close();
  },
  methods: {
    clickBaseInfo() {
      this.baseInfo = !this.baseInfo;
    },
    clickVehicleLicense() {
      this.vehicleLicense = !this.vehicleLicense;
    },
    clickVehicleSpecificInformation() {
      this.vehicleSpecificInformation = !this.vehicleSpecificInformation;
    },
    clickMileage() {
      this.mileage = !this.mileage;
    },
    clickDamages() {
      this.damages = !this.damages;
    },
    handleReset() {
      this.$refs.esign.reset();
      this.inter = setInterval(() => {
        this.handleGenerate();
      }, 1000);
    },
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res;
          if (this.resultImg) {
            this.generate = true;
            clearInterval(this.inter);
          }
        })
        .catch(() => {
          this.generate = false;
        });
    },
    nextStep() {
      this.handleGenerate();
      this.parseThoto(this.toPhotoList);
      Indicator.open({
        text: "loading...",
        spinnerType: "fading-circle",
      });
    },

    async parseThoto(key) {
      const response = await this.$api.vehicle.uploadImage(key);
      if (response) {
        this.imageList = response;
        this.sendStockData();
      }
    },
    async searchList(key) {
      let response = await this.$api.vehicle.searchVehicleList(key);
      if (response) {
        this.checkResult = response;
      }
    },
    async sacnPhoto(key) {
      let response = await this.$api.vehicle.sacnPhoto(key);
    },
    ConvertYesAndNo(state) {
      if (state == this.$t("lang.Yes")) {
        return "YES";
      } else if (state == this.$t("lang.No")) {
        return "NO";
      }
    },
    CoverInjury(state) {
      if (state == this.$t("lang.Perfect")) {
        return "Perfect";
      } else if (state == this.$t("lang.Minor")) {
        return "Minor";
      } else if (state == this.$t("lang.Medium")) {
        return "Medium";
      } else if (state == this.$t("lang.Major")) {
        return "Major";
      }
    },
    ConvertColor(state) {
      if (state == this.$t("lang.White")) {
        return "White";
      } else if (state == this.$t("lang.Silver")) {
        return "Silver";
      } else if (state == this.$t("lang.Grey")) {
        return "Grey";
      } else if (state == this.$t("lang.Blue")) {
        return "Blue";
      } else if (state == this.$t("lang.Green")) {
        return "Green";
      } else if (state == this.$t("lang.Red")) {
        return "Red";
      } else if (state == this.$t("lang.Yellow")) {
        return "Yellow";
      } else if (state == this.$t("lang.Orange")) {
        return "Orange";
      } else if (state == this.$t("lang.Black")) {
        return "Black";
      } else if (state == this.$t("lang.Brown")) {
        return "Brown";
      } else if (state == this.$t("lang.Beige")) {
        return "Beige";
      } else {
        return "";
      }
    },
    sendStockData() {
      var id = this.$store.state.id;
      var signture = this.resultImg;
      var plateNo = this.checkResult[0].legacyPlateNo;
      var model = this.checkResult[0].model;
      var vin = this.checkResult[0].vin;
      var provincialCapital = this.plateNumber.substring(0, 1);
      var latitude = this.$store.state.latitude;
      var longitude = this.$store.state.longitude;
      var mileage = this.$store.state.mileage;
      let warningTriangleInCar = this.ConvertYesAndNo(
        this.$store.state.warningTriangleInCar
      );
      let carIsMobileAndInUse = this.ConvertYesAndNo(
        this.$store.state.carIsMobileAndInUse
      );
      // let fireExtinguisher = this.ConvertYesAndNo(
      //   this.$store.state.fireExtinguisher
      // );
      // let floorMat = this.ConvertYesAndNo(this.$store.state.floorMat);
      // let exteriorColour = this.ConvertColor(this.$store.state.carBodyPhoto);
      // let interiorColour = this.ConvertColor(this.$store.state.interiorPhoto);
      let damagesSiteAndId = [];
      let leftFrontImage;
      let rightBackImage;
      let licenseImage;
      let odometerImage;
      this.imageList.forEach((item) => {
        if ("leftFrontImage" === item.position) {
          leftFrontImage = {
            id: item.id,
          };
        } else if ("rightBackImage" === item.position) {
          rightBackImage = {
            id: item.id,
          };
        } else if ("licenseImage" === item.position) {
          licenseImage = {
            id: item.id,
          };
        } else if ("odometerImage" === item.position) {
          odometerImage = {
            id: item.id,
          };
        } else {
          damagesSiteAndId.push({
            site: item.position,
            id: item.id,
          });
        }
      });
      let damages = {
        damageLevel: this.CoverInjury(this.$store.state.damages),
        damageImages: damagesSiteAndId,
      };

      var location = {
        latitude: latitude,
        longitude: longitude,
      };
      var data = {
        signture,
        plateNo,
        model,
        vin,
        provincialCapital,
        location,
        mileage,
        warningTriangleInCar,
        carIsMobileAndInUse,
        // fireExtinguisher,
        // floorMat,
        // exteriorColour,
        // interiorColour,
        leftFrontImage,
        rightBackImage,
        licenseImage,
        odometerImage,
        damages,
      };

      this.uploadStockData(id, data);
    },
    async uploadStockData(id, data) {
      console.log("提交的data---" + data.location.latitude);
      console.log("提交的data---" + data.location.longitude);
      let response = await this.$api.vehicle.uploadStockData(id, data);
      if (response.message === "created") {
        Indicator.close();
        this.popupVisible = true;
      }
    },
    successBtn() {
      this.$store.commit("updateCarInfo", {
        hasStock: true,
      });

      this.$router.go(-window.localStorage.getItem("routerNum"));
    },
  },
};
</script>
<style scoped lang="scss">
.border-color {
  border: 0.01rem solid #e6e6e6;
  background-color: white;
}
.info-list-wrap {
  .Vehicle-basic-information {
    height: 0.38rem;
    font-size: 0.16rem;
    line-height: 0.22rem;
    color: #333333;
    font-weight: 600;
    background-color: #ffffff;
    border-bottom: solid 0.01rem #ebebeb;
    align-items: center;
    display: flex;
    padding-left: 0.1rem;
    position: relative;

    img {
      padding-right: 0.08rem;
    }
    .unflod {
      position: absolute;
      right: 0.2rem;
      display: flex;
      align-items: center;
    }
    .drop-down-icon {
      padding-right: 0rem;
    }
  }
  .info-list {
    font-size: 0.14rem;
    line-height: 0.2rem;
    color: #333333;
    background-color: #ffffff;
    border-bottom: solid 0.01rem #f6f6f6;
    align-items: center;
    display: flex;
    justify-content: space-between;
    .userDetail {
      padding-right: 0.2rem;
      font-size: 0.14rem;
      color: #999999;
      line-height: 0.2rem;
      display: flex;
      align-content: center;
      img {
        padding-left: 0.1rem;
        display: block;
        margin: auto;
      }
    }

    .userDetailPhone {
      padding-right: 0.2rem;
      font-size: 0.14rem;
      text-decoration: none;
      line-height: 0.2rem;
    }
  }
  .label-info-height {
    padding: 0.08rem 0rem 0.08rem 0.2rem;
    border-bottom: 0rem;
  }
  .main-info-height {
    padding: 0.1rem 0rem 0.1rem 0.2rem;
  }
  .exteriorPhoto {
    padding: 0.13rem 0rem 0.13rem 0.2rem;
    font-size: 0.14rem;
    line-height: 0.2rem;
    color: #333333;
    background-color: #ffffff;
    border-bottom: solid 0.01rem #f6f6f6;
    justify-content: center;
    display: flex;
    flex-direction: column;
    p {
      font-size: 0.12rem;
      color: #999999;
      line-height: 0.17rem;
      padding-top: 0.03rem;
    }
    .photo-wrap {
      display: flex;
      .camera-wrap {
        width: 0.8rem;
        height: 0.8rem;
        border: dashed 0.01rem #d8d8d8;
        margin: 0.1rem 0.2rem 0rem 0rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 0.33rem;
          height: 0.29rem;
        }
      }
    }
    .photo-word {
      display: flex;
      p {
        width: 0.82rem;
        padding-right: 0.2rem;
        display: flex;
        justify-content: center;
      }
    }
  }
}
.label-wrap {
  padding: 0.1rem 0rem;
  background-color: #ffffff;
  .vehicle-license-wrap {
    display: flex;
    justify-content: center;
    .camera-wrap {
      width: 3.35rem;
      height: 1.2rem;
      border: dashed 0.01rem #d8d8d8;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        padding-top: 0.37rem;
      }
      p {
        font-size: 0.12rem;
        color: #999999;
        line-height: 0.17rem;
        padding-top: 0.03rem;
      }
    }
  }
}
.info-list-content {
  .info-list {
    padding-left: 0.4rem;
    background-color: #f9f9f9;
  }
}
.esign-title-wrap {
  background-color: #ffff;
  .esign-title {
    display: flex;
    justify-content: space-between;
    padding-top: 0.12rem;
  }
  .esign-title-font {
    font-size: 0.16rem;
    color: #333333;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .esign-content {
    display: flex;
    img {
      padding-left: 0.1rem;
      padding-right: 0.08rem;
    }
  }
  .esign-hint {
    font-size: 0.12rem;
    color: #999999;
    padding: 0.03rem 0rem 0.13rem 0.42rem;
  }
  p {
    color: #4c9edd;
    font-size: 0.14rem;
    padding-right: 0.2rem;
  }
}
.tip-success-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    padding-top: 0.24rem;
  }
  p {
    font-size: 0.18rem;
    color: #333333;
    font-weight: 600;
    padding-top: 0.1rem;
  }
  .tip-success {
    font-size: 0.14rem;
    color: #999999;
    padding: 0.1rem 0.2rem 0.24rem 0.2rem;
    .tip-success-content {
      text-align: center;
    }
  }
  .mint-button {
    width: 2.75rem;
    margin: 0rem 0.2rem 0.24rem 0.2rem;
    background-color: #4c9edd;
    color: #ffffff;
    font-size: 0.16rem;
  }
}
.wx-photo {
  width: 3rem;
  height: 1.2rem;
  margin-left: -0.3rem;
}
.userDetail-photo {
  margin-left: -0.1rem;
  .camera-photo-small {
    display: flex;
    display: block;
    .wx-photo {
      width: 0.8rem;
      height: 0.8rem;
      padding-left: 0.4rem;
    }
  }
}
.camera-photo {
  padding-left: 0rem;
  margin-left: -0.1rem;
}
.spacing {
  height: 0.1rem;
  width: 3.75rem;
  color: #f6f6f6;
}
.stock-taking-btn {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}
</style>
